import React from 'react';
import {graphql} from 'gatsby';

import Layout from '../components/Layout';

import BookingBanner from '../components/Page/BookingBanner';

import PageDescription from '../components/Page/home-cleaning/PageDescription';
import RegularCleaning from '../components/Page/home-cleaning/RegularCleaning';
import DeepCleaning from '../components/Page/home-cleaning/DeepCleaning';
import HotspotGallery from '../components/Page/home-cleaning/HotspotGallery';
import SEO from '../components/Seo';
import {useI18next} from 'gatsby-plugin-react-i18next';
import FAQ from '../components/Page/home-cleaning/FAQ';
import PageHero from '../components/shared/hero';
import siteData from '../config/site_data.json';

const HomeCleaningPage = ({data}) => {
  const {language, t} = useI18next();
  const {background, backgroundMobile} = data;
  const {heading, text, button_text} = siteData.home_cleaning.hero;

  return (
    <Layout headerStyle="homeCleaning">
      <SEO
        title={t('home_cleaning_seo_title')}
        description={t('home_cleaning_seo_description')}
        lang={language}
        keywords={t('home_cleaning_keywords', {returnObjects: true})}
      />
      <PageHero
        // imgPosition={"100% 0%"}
        content={{
          heading,
          text,
          button: button_text,
        }}
        imgObj={{
          background,
          backgroundMobile: backgroundMobile,
          backgroundTablet: background,
        }}
        // pt={90}
      />
      <PageDescription button={false} />
      <HotspotGallery />
      <RegularCleaning />
      <DeepCleaning />
      <BookingBanner
        color="primary"
        filename="office-cleaning-book-now.png"
        mobileFileName="office-cleaning-book-now-mobile.png"
        heading={`save_time_for_happy_things`}
      />
      <FAQ />
    </Layout>
  );
};

export default HomeCleaningPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {in: ["common", "home_cleaning", "seo_home_cleaning"]}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    background: file(relativePath: {eq: "home-cleaning-hero.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundMobile: file(
      relativePath: {eq: "home-cleaning-hero-mobile.png"}
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
  }
`;
