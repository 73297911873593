import React, { useEffect, useState } from 'react';
import tw, { styled, css } from 'twin.macro';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Section } from '../../Grid';
import { Heading } from '../../Typography';

const Wrapper = styled(Section)``;

const StyledHeading = styled(Heading)`
  ${tw`text-secondary`}
  max-width: 422px;
  font-size: 24px;
  margin-bottom: 46px;

  @media ${props => props.theme.screens.md} {
    margin-bottom: 60px;
    font-size: 30px;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 50px;
  }
`;

const FAQWrapper = styled.div`
  ${tw`hidden lg:flex flex-row`}

  @media ${props => props.theme.screens.lg} {
    margin-left: 0;
  }
`;

const QuestionShadowWrapper = styled.div`
  ${tw`relative`}

  &::before,
  &::after {
    ${tw`absolute  content`}
    height: 100px;
    width: calc(100% - 35px);
    z-index: 5;
    pointer-events: none;
  }

  &::before {
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  &::after {
    height: 126px;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
  }
`;

const QuestionWrapper = styled.ul`
  max-width: 419px;
  margin-right: 30px;

  /* background: red; */

  padding-right: 15px;

  max-height: 316px;
  overflow-y: scroll;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d7dddc;
    border-radius: 6px;
    margin-left: 10px;
    visibility: hidden;
  }

  /* Show the scrollbar on hover only */
  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
`;

const Question = styled.li<{ selected: boolean }>`
  ${tw`text-200 relative cursor-pointer hover:text-secondary transition-colors`}
  font-size: 15px;
  line-height: 24px;
  padding-left: 20px;
  font-weight: ${props => (props.selected ? "bold" : '500')};
  color: ${props => (props.selected ? props.theme.colors.secondary : '')};

  &::before {
    ${tw`absolute left-0 top-0 h-full bg-primary transition-opacity`}
    content:'';
    border-radius: 4px;
    width: 4px;

    opacity: ${props => (props.selected ? 1 : 0)};
  }

  &:not(:last-child) {
    margin-bottom: 26px;
  }

  &:first-child,
  &:last-child {
    position: relative;
    z-index: 6;
  }

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 149%;
    padding-left: 24px;

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
`;

export const AnswerWrapper = styled.div<{ position?: 'left' }>`
  ${tw`flex flex-col items-center`}
  /* flex-grow: 1; */
  margin-left: 40px;

  ${prosp => (prosp.position === 'left' ? leftAlign : null)};
`;

export const Answer = styled.p`
  ${tw`text-secondary border relative bg-white`}

  max-width: 615px;
  font-size: 15px;
  line-height: 24px;
  padding: 37px 42px;
  font-weight: 500;
  box-shadow: 9px 12px 26px rgba(0, 0, 0, 0.05);
  border-radius: 14px;

  @media ${props => props.theme.screens.md} {
   font-size: 19px;
   line-height: 31px;
  }

  &::before {
    ${tw`absolute bottom-0 right-0`}
    content: '';

    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;

    border-right: 50px solid transparent;

    transform: translateY(40%);
  }
`;

export const AvatarWrapper = styled.div`
  ${tw`flex items-center mx-auto mt-4 md:mt-12 justify-end`}

  font-size: 19px;
  line-height: 132.5%;

  div {
    ${tw`mr-4`}
  }

  picture {
    width: 63px;
    height: 63px;
  }

  @media ${props => props.theme.screens.lg} {
    margin: 0;
    ${tw`mt-14 ml-auto`}

    max-width: max-content;
    font-size: 30px;
    line-height: 132.5%;

    picture {
      width: 91px;
      height: 91px;
    }
  }
`;

export const Author = styled.span`
  ${tw`text-secondary block text-right`}
`;

export const From = styled.span`
  ${tw`text-200 block w-full`}
`;

/// Mobile

const MobileWrapper = styled.div`
  ${tw`lg:hidden`}

  list-style: none;
`;

const MobileAnswer = styled.div`
  ${tw`text-secondary font-medium text-center md:text-left`}
  border: 1px solid rgba(210, 210, 210, 0.4);
  box-shadow: 9px 12px 26px rgba(0, 0, 0, 0.05);
  border-radius: 23px;

  font-size: 15px;
  line-height: 24px;
  padding: 32px 34px;
  margin-bottom: 22px;

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 30px;
    padding: 45px 83px;
    margin-bottom: 35px;
  }
`;

const leftAlign = css`
  ${Answer} {
    ${tw`font-medium`}
    font-size: 16px;
    line-height: 25px;

    &::before {
      display: none;
    }

    &::after {
      ${tw`absolute bottom-0 left-0`}
      content: '';

      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;

      border-left: 50px solid white;

      transform: translateY(40%);
    }

    @media ${props => props.theme.screens.md} {
      font-size: 22px;
      line-height: 158.5%;
      margin-top: 128px;
    }

    @media ${props => props.theme.screens.xl} {
      margin-top: 28px;
    }
  }

  ${AvatarWrapper} {
    ${tw`flex-row-reverse ml-0 mr-auto mt-12 md:mt-20`}
    div {
      ${tw`mr-0 ml-4`}
    }
  }

  ${Author} {
    ${tw`text-left`}
  }
`;

const FAQ: React.FC = () => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<{
    id: string;
    question: string;
    answer: string;
    name: string;
    company: string;
    avatar: string;
  }>({ id: '', question: '', answer: '', name: '', company: '', avatar: '' });

  const items = t("faq_items").split(";").map((item, index) => {
    const splitted = item.split("|")
    return {
      id: index,
      question: splitted[0],
      answer: splitted[1],
    }
  })

  const handleSelect = (id: any) => {
    setSelected(items.find((item: any) => item.id === id));
  };

  useEffect(() => {
    if (!selected.id) {
      setSelected({ ...items[0] });
    }
  }, []);

  return (
    <Wrapper>
      <Container>
        <StyledHeading>{t("faq_heading")}</StyledHeading>
        <FAQWrapper>
          <QuestionShadowWrapper>
            <QuestionWrapper>
              {items.map(({ id, question }: any) => (
                <Question
                  key={id}
                  onClick={() => handleSelect(id)}
                  selected={selected!.id == id}
                >
                  {t(question)}
                </Question>
              ))}
            </QuestionWrapper>
          </QuestionShadowWrapper>
          <AnswerWrapper>
            {selected && <Answer>{t(selected!.answer)}</Answer>}
          </AnswerWrapper>
        </FAQWrapper>
        <MobileWrapper>
          {items.map(({ id, question }: any) => (
            <React.Fragment key={id}>
              <Question
                onClick={() => handleSelect(id)}
                selected={selected!.id == id}
              >
                {t(question)}
              </Question>
              {selected.id === id && (
                <MobileAnswer>{t(selected!.answer)}</MobileAnswer>
              )}
            </React.Fragment>
          ))}
        </MobileWrapper>
      </Container>
    </Wrapper>
  );
};

export default FAQ;
