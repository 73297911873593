import React from 'react';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Section } from '../../Grid';
import { Heading } from '../../Typography';
import { StyledButton, Description } from './RegularCleaning';
import BookNowController from '../../BookNowController';
import { List as BulletList } from '../office-cleaning/Included';
const Wrapper = styled(Section)``;

const StyledHeading = styled(Heading)`
  ${tw`text-secondary lg:mb-24`}
  font-size: 24px;
  color: black;
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom} !important;
  line-height: 32px;

  @media ${props => props.theme.screens.md} {
    font-size: 30px;
    line-height: 42px;
  }
`;

const StyledBlock = styled.div`
  padding: 35px;

  @media ${props => props.theme.screens.md} {
    margin-bottom: 100px;
  }
  @media ${props => props.theme.screens.lg} {
    padding: 100px;
    margin-bottom: 0px;
  }
`
const DeepCleaning = () => {
  const { t } = useTranslation();

  const createList = (content: string) => content.split(';');

  return (
    <Wrapper>
      <Container>
        <StyledHeading marginTop="80px" marginBottom="30px">
          {t('deep_cleaning_space_heading')}
        </StyledHeading>
        <Description
          dangerouslySetInnerHTML={{ __html: t('deep_cleaning_space_text') }}
        />
        <Description marginTop="30px" fontWeight="bold">
          {t('deep_cleaning_space_type')}
        </Description>
        <BulletList marginBottom="70px">
          {createList(t(`deep_cleaning_space_list`)).map((item: any) => (
            <li key={item} dangerouslySetInnerHTML={{ __html: item }} />
          ))}
        </BulletList>
        <BookNowController>
          <StyledButton>{t('deep_cleaning_space_button')}</StyledButton>
        </BookNowController>
        <StyledBlock
          style={{ marginTop: 100 }}
          className="bg-gray-100 w-full h-full flex flex-col justify-center items-center rounded-lg text-center"
        >
          <StyledHeading marginBottom="20px">
            {t('price_list_home_heading')}
          </StyledHeading>
          <Description
            dangerouslySetInnerHTML={{ __html: t('price_list_home_text') }}
          />
        </StyledBlock>
      </Container>
    </Wrapper>
  );
};

export default DeepCleaning;
