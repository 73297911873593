import React, { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import data from '../../../config/site_data.json';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Section } from '../../Grid';
import { Heading } from '../../Typography';

import HotspotImage from '../../HotspotImage';

const Wrapper = styled(Section)``;

const StyledHeading = styled(Heading)`
  ${tw`text-secondary lg:mb-24`};
  font-size: 24px;
  line-height: 32px;
  
  @media ${props => props.theme.screens.md} {
    font-size: 30px;
    line-height: 42px;
  }
`;

const GalleryControls = styled.div`
  ${tw`flex w-full p-1 my-6 md:mt-0`}
  border-radius: 14px;
  background: #f5f4f2;

  @media ${props => props.theme.screens.md} {
    border-radius: 16px;
  }
`;

const GalleryButton = styled.span<{ selected: any }>`
  ${tw`flex-grow text-center text-secondary py-2 bg-transparent cursor-pointer hover:opacity-80 transition-opacity`}
  font-weight: bold;
  font-size: 15px;
  border-radius: 12px;

  background-color: ${props => (props.selected ? 'white' : '')};
  color: ${props => (props.selected ? "#009259" : '')};

  @media ${props => props.theme.screens.md} {
    font-size: 21px;
    border-radius: 14px;
  }
`;

const GalleryWrapper = styled.div`
  border-radius: 16px;
  overflow: hidden;
`;

const HotspotGallery = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState({
    title: '',
    filename: '',
    hotspots: undefined,
  });
  const { heading, items } = data.home_cleaning.hotpost_gallery;

  useEffect(() => {
    if (items && items[0]) {
      setSelected(items[0]);
    }
  }, []);

  return (
    <Wrapper>
      <Container>
        <StyledHeading>{t(heading)}</StyledHeading>
        <GalleryControls>
          {items.map((item: any) => (
            <GalleryButton
              key={`${item.title}-control`}
              onClick={() => setSelected(item)}
              selected={item.title === selected.title}
            >
              {t(item.title)}
            </GalleryButton>
          ))}
        </GalleryControls>

        <GalleryWrapper>
          <HotspotImage
            key={selected.title}
            filename={selected.filename}
            hotspots={selected.hotspots}
          />
        </GalleryWrapper>
      </Container>
    </Wrapper>
  );
};

export default HotspotGallery;
